<template>
    <template v-if="props.title">
        {{ props.title }}
    </template>
    <div id="main-logo" v-else></div>
</template>
<style>
#main-logo {
    background-image: var(--logo-path);
    width: var(--logo-width);
    height: var(--logo-height);
    @apply mx-auto bg-no-repeat bg-center bg-contain block;
}
</style>
<script setup>
import { ref } from 'vue'
const props = defineProps({
    title: String,
    class: String,
})
</script>
