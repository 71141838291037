<script setup>
import { computed } from 'vue'
import { usePage, Link } from '@inertiajs/vue3'
const page = usePage()
const breadcrumb = computed(() => {
    return page.props.breadcrumb
})
</script>

<template>
    <ul class="breadcrumb">
        <li v-for="item in breadcrumb">
            <Link v-if="item.route != null" :href="item.route">{{ item.name }}</Link>
            <span v-else>{{ item.name }}</span>
        </li>
    </ul>
</template>

<style scoped lang="scss"></style>
