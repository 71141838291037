<script setup>
import { Link, router, usePage } from '@inertiajs/vue3'
import ApplicationMark from '@/Components/ApplicationMark.vue'
import { computed, ref } from 'vue'
import ProfileMenu from '@/Layouts/ProfileMenu.vue'
import { _ } from 'lodash'
import VNavLink from './Navigation/VNavLink.vue'
import VMobileNavLink from '@/Layouts/Navigation/VMobileNavLink.vue'
import { trans } from '@/vendor/inertia-scaffold/trans.js'
const page = usePage()
const props = defineProps({
    menus: {
        type: String,
        default: '[]',
    },
    profileMenus: {
        type: String,
        default: '[]',
    },
})
const menus = computed(() => JSON.parse(props.menus))
const profileMenus = computed(() => JSON.parse(props.profileMenus))

if (page.props.simulating_user && page.props.simulating_user.admin_user_first_name) {
    const returnRoute = {
        name: trans('menu.end_simulation', {
            type: page.props.simulating_user.admin_user_first_name,
        }),
        named_route: 'end_simulation',
        route: route('end_simulation'),
        target: '_self',
        type: 'profile',
    }

    profileMenus.value[0].children.push(returnRoute)
}

const showingNavigationDropdown = ref(false)
const showingProfileDropdown = ref(false)
function toggleMobileMenu() {
    showingProfileDropdown.value = false
    showingNavigationDropdown.value = !showingNavigationDropdown.value
}
const mobileProfileMenu = computed(() => {
    return _.first(profileMenus.value).children
})
function toggleProfileMenu() {
    showingNavigationDropdown.value = false
    showingProfileDropdown.value = !showingProfileDropdown.value
}
const logout = () => {
    router.post(route('logout'))
}
const companyName = computed(() => {
    return _.get(page, 'props.auth.user.company.name', '')
})
</script>

<template>
    <nav class="bg-header">
        <div class="mx-auto px-2 sm:px-6 xl:px-8">
            <!-- Primary Navigation Menu -->
            <div class="relative flex h-16 items-center justify-between">
                <!-- Hamburger -->
                <div class="absolute inset-y-0 left-0 flex items-center xl:hidden">
                    <div class="-mr-2 flex items-center xl:hidden ml-[5%]">
                        <button
                            class="inline-flex items-center justify-center p-2 rounded-md text-gray-100 focus:outline-none transition duration-150 ease-in-out"
                            @click="toggleMobileMenu"
                        >
                            <svg
                                class="h-6 w-6"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    :class="{
                                        hidden: showingNavigationDropdown,
                                        'inline-flex': !showingNavigationDropdown,
                                    }"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                                <path
                                    :class="{
                                        hidden: !showingNavigationDropdown,
                                        'inline-flex': showingNavigationDropdown,
                                    }"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                </div>

                <!-- Logo -->
                <div
                    class="flex flex-1 xl:flex-initial items-center justify-center xl:justify-start"
                >
                    <div class="shrink-0 flex items-center my-[15px]">
                        <Link :href="route('dashboard.index')" class="mark-visible logo">
                            <ApplicationMark
                                :title="page.props.auth.user.company.name"
                                class="block h-9 w-auto"
                            />
                        </Link>
                    </div>
                </div>

                <!-- Navigation Links -->
                <div class="hidden xl:-my-px xl:flex">
                    <template v-for="item in menus">
                        <v-nav-link
                            v-if="
                                (item.route && item.route != '#') ||
                                (item.children && item.children.length > 0)
                            "
                            :item="item"
                        >
                            {{ item.name }}
                        </v-nav-link>
                    </template>
                </div>

                <div
                    class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
                >
                    <profile-menu
                        @toggleMobile="toggleProfileMenu"
                        :profile-menus="profileMenus"
                    ></profile-menu>
                </div>
            </div>
        </div>

        <!-- Responsive Navigation Menu -->
        <div class="xl:hidden">
            <div
                :class="{
                    'flex flex-col': showingNavigationDropdown,
                    hidden: !showingNavigationDropdown,
                }"
            >
                <v-mobile-nav-link v-for="item in menus" :item="item">{{
                    item.name
                }}</v-mobile-nav-link>
            </div>
            <div
                :class="{
                    profile: true,
                    'flex flex-col': showingProfileDropdown,
                    hidden: !showingProfileDropdown,
                }"
            >
                <v-mobile-nav-link
                    v-for="item in mobileProfileMenu"
                    :item="item"
                    class="text-right justify-end pr-[5%]"
                    >{{ item.name }}</v-mobile-nav-link
                >
            </div>
        </div>
    </nav>
</template>

<style scoped lang="scss">
nav {
    z-index: 999;
    @apply fixed w-full;
    &.bg-header {
        background-color: var(--header-bg-color);
    }
    .logo {
        @apply text-white max-w-[150px] sm:max-w-[275px] text-ellipsis whitespace-nowrap overflow-hidden h-[40px] leading-[40px] text-[25px] font-bold;
        &.mark-visible {
            display: var(--header-display-logo);
        }
        font-family: 'Saira Extra Condensed';
    }
}
</style>
