<script setup>
import { computed } from 'vue'
import VDropdown from './VDropdown.vue'
import { Link, router } from '@inertiajs/vue3'
import _ from 'lodash'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    level: {
        type: Number,
        default: 0,
        required: false,
    },
    class: {
        default: null,
        required: false,
    },
    dropdownAlign: {
        type: String,
        default: 'left',
        required: false,
    },
    withSvg: {
        type: Boolean,
        default: true,
        required: false,
    },
})
const generatedClasses = computed(() => {
    let classString = ''
    if (props.class !== null && typeof props.class === 'Array' && props.class.length > 0) {
        classString += props.class.join(' ')
    } else if (typeof props.class === 'string') {
        classString += props.class
    }
    classString += ' transition !hover:text-primary'

    if (props.item.active || childIsActive(props.item)) {
        classString += ' active'
    }
    if (props.level == 0) {
        classString += ' mainbutton'
    }
    return classString
})
const logout = () => {
    router.post(route('logout'))
}
function childIsActive(item) {
    if (item.children && item.children.length > 0) {
        return _.some(item.children, function (child) {
            return childIsActive(child)
        })
    } else {
        return item.named_route == route().current()
    }
}
</script>

<template>
    <v-dropdown
        v-if="props.item.children && props.item.children.length > 0"
        :align="dropdownAlign"
        class="flex items-center"
    >
        <template #trigger>
            <button
                :id="'dropdown' + props.item.name + 'button'"
                type="button"
                :class="generatedClasses"
            >
                <slot></slot>
                <svg
                    v-if="withSvg"
                    class="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                >
                    <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                    />
                </svg>
            </button>
        </template>

        <template #content>
            <ul
                class="text-sm text-gray-700 dark:text-gray-200"
                :aria-labelledby="'dropdown' + props.item.name + 'button'"
            >
                <template v-for="subitem in item.children">
                    <li>
                        <v-nav-link :item="subitem" :level="level + 1">{{
                            subitem.name
                        }}</v-nav-link>
                    </li>
                    <li class="line" v-if="subitem.has_separator"></li>
                </template>
            </ul>
        </template>
    </v-dropdown>
    <form v-else-if="item.named_route == 'logout'" @submit.prevent="logout">
        <button type="submit" :class="generatedClasses + ' w-full'">
            <slot></slot>
        </button>
    </form>
    <Link v-else-if="item.route" :href="item.route" :class="generatedClasses">
        <slot></slot>
    </Link>
</template>

<style scoped lang="scss">
.line {
    height: 1px;
    background-color: #e1e1e1;
    margin: 10px 0;
}
button,
a {
    &.mainbutton {
        color: var(--header-link-color);
    }
    &:not(.mainbutton) {
        color: var(--main-text-color);
        &:hover {
            background-color: var(--header-submenu-bg-color);
        }
    }
    @apply hover:text-primary text-sm flex items-center py-0 pr-6 pl-3.5 min-h-[30px] whitespace-nowrap;
    &.active {
        @apply font-bold;
    }
    &:hover {
        @apply text-primary;
    }
}
ul {
    @apply min-w-[180px];
}
</style>
