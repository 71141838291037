<script setup>
import { Head, router, usePage } from '@inertiajs/vue3'
import VHeader from '@/Layouts/VHeader.vue'
import { computed, ref, watch } from 'vue'
import VBreadCrumb from '@/Components/BaseComponents/VBreadCrumb.vue'
import VToast from '@/Components/BaseComponents/VToast.vue'
import { Modal } from '@/vendor/inertia-modal.js'
import { trans } from '@/vendor/inertia-scaffold/trans.js'
import VAlertBanner from '@/Components/BaseComponents/VAlertBanner.vue'

const page = usePage()
const appDetails = page.props.app_details
window.Laravel = { jsPermissions: JSON.parse(page.props.jsPermissions) }

const props = defineProps({
    title: String,
    contentOnlyTitle: {
        type: String,
        default: '',
    },
    fluid: {
        type: Boolean,
        default: false,
    },
    mainClass: {
        type: String,
        default: 'py-10',
    },
    breadcrumb: {
        type: Boolean,
        default: false,
    },
    breadcrumbClasses: {
        type: String,
        default: 'px-4 sm:px-6 lg:px-auto container mx-auto',
    },
    contentOnly: {
        type: Boolean,
        default: false,
    },
    userCompanies: {
        type: Array,
    },
    redirectRoute: {
        type: String,
    },
    administration: {
        type: Boolean,
        default: false,
    },
})
const classes = computed(() => {
    return {
        'px-4 sm:px-6 lg:px-auto m-auto container app-container	': !props.fluid,
    }
})

const message = computed(() => page.props.message)
const toastKey = ref()
watch(
    () => message.value,
    (value) => {
        toastKey.value = Date.now()
    }
)

if (!page.props.active_license) {
    router.visit(route('license.edit.warning'))
}

const shootConfettiBoolean = computed(() => page.props.confetti)
watch(
    () => shootConfettiBoolean.value,
    (value) => {
        if (value) {
            shootConfetti()
        }
    }
)

function loadScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src
        script.async = true
        script.onload = resolve
        script.onerror = reject
        document.head.appendChild(script)
    })
}

const shootConfetti = async () => {
    try {
        await loadScript(
            'https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.3/dist/confetti.browser.min.js'
        )

        confetti({
            spread: 180,
            ticks: 400,
            gravity: 0.75,
            decay: 0.9,
            startVelocity: 35,
            scalar: 1.5,
            particleCount: 100,
            origin: {
                x: 0.5,
                y: 0,
            },
        })
    } catch (error) {
        console.error('Fout bij het laden van het confetti-script:', error)
    }
}
</script>
<template>
    <div v-if="!props.contentOnly">
        <Head :title="title" />
        <v-header
            :menus="administration ? page.props.admin_menus : page.props.menus"
            :profile-menus="
                administration ? page.props.admin_profile_menus : page.props.profileMenus
            "
        />
        <div
            :class="{
                'min-h-screen': true,
                'pt-7': true,
                'main-bg-color': !page.props.with_public_footer,
            }"
        >
            <!-- Page Content -->
            <main :class="mainClass">
                <v-alert-banner banner-class="bg-warning-banner" v-if="page.props.trial_expiring">
                    <template #link>
                        <div
                            class="inline-block"
                            v-html="
                                trans('auth.trial_about_to_expire', {
                                    route: route('license.edit'),
                                })
                            "
                        />
                    </template>
                </v-alert-banner>

                <v-bread-crumb v-if="breadcrumb" :class="breadcrumbClasses" />
                <div :class="classes">
                    <slot />
                    <Modal />
                </div>
            </main>
            <p
                id="footer_text"
                v-if="page.props.with_public_footer"
                v-html="
                    trans('document.pdf_download_instructions_email', {
                        document: props.title,
                    })
                "
            ></p>
            <!--            <a-->
            <!--                id="footer_logo"-->
            <!--                :class="{ footer_logo_grey: page.props.with_public_footer }"-->
            <!--                href="https://www.numnum.be/nl"-->
            <!--                target="_blank"-->
            <!--            ></a>-->
            <p
                id="footer_subtext"
                v-if="page.props.with_public_footer"
                v-html="
                    trans('email.baseline', { type: appDetails.lower_name, link: appDetails.url })
                "
            ></p>
        </div>
        <div class="fixed bottom-3 left-3 flex flex-col-reverse z-2000">
            <template v-if="message">
                <v-toast :key="toastKey" v-if="typeof message === 'string'">{{ message }}</v-toast>
                <v-toast
                    :key="toastKey"
                    v-else-if="typeof message === 'object'"
                    :type="message.type"
                    :duration="message.duration"
                    >{{ message.message }}</v-toast
                >
            </template>
        </div>
    </div>
    <template v-else>
        <Head :title="contentOnlyTitle" />
        <slot />
    </template>
</template>
<style scoped lang="scss">
#footer_logo {
    background-image: var(--logo-negative-path);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    width: 70px;
    height: 40px;
    background-color: #bbb;
    @apply mx-auto bg-no-repeat bg-center bg-contain block;
}
#footer_logo.footer_logo_grey {
    background-image: var(--logo-grey-path);
    background-color: transparent;
}
#footer_subtext {
    @apply text-center text-xs text-gray-500;
    font-style: italic;
    line-height: 20px;
    margin: 40px 0;
}
#footer_text {
    @apply text-center text-sm;
    margin-bottom: 30px;
    padding: 0 30px;
}

.z-2000 {
    z-index: 2000;
}
</style>
<style lang="scss">
.app-container {
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 1420px;
}
#footer_text a {
    @apply text-sm #{!important};
}

.bg-header {
    background-color: var(--header-bg-color);
}
</style>
