<script setup>
import { defineEmits } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import VNavLink from './Navigation/VNavLink.vue'
import VMobileNavLink from '@/Layouts/Navigation/VMobileNavLink.vue'
import { _ } from 'lodash'
import VButton from '@/Components/BaseComponents/VButton.vue'

const page = usePage()
const props = defineProps({
    active: {
        type: Boolean,
        required: false,
    },
    level: {
        type: Number,
        default: 0,
        required: false,
    },
    class: {
        default: null,
        required: false,
    },
    profileMenus: {
        type: Array,
        required: true,
    },
})
const emit = defineEmits(['toggleMobile'])
function toggleProfileMenu() {
    //trigger emit
    emit('toggleMobile')
}
</script>

<template>
    <div class="ml-3 relative xl:block hidden">
        <v-nav-link
            v-for="menu in profileMenus"
            :item="menu"
            dropdown-align="right"
            :with-svg="false"
        >
            <div class="image">
                <img
                    v-if="page.props.auth.user.profile_photo_url"
                    class="h-8 w-8 rounded-full object-cover"
                    :src="page.props.auth.user.profile_photo_url"
                    :alt="page.props.auth.user.name"
                />
                <svg class="ico" x="0px" y="0px" viewBox="0 0 40 40" v-else>
                    <path
                        d="M26.684,28.965c-1.473-0.737-1.348-1.641-1.348-2.578c0-1.602,1.309-3.652,2.363-5.742 c1.211-2.441,0.996-4.004,0.82-4.629c0.195-0.684,0.371-1.855,0.371-3.184c0-3.711-1.582-7.812-6.035-7.812 c-0.582,0-2.202,0.092-2.855,0.223c-5.083-0.223-8.653,3.878-8.653,7.589c0,1.328,0.176,2.5,0.371,3.184 c-0.176,0.625-0.391,2.188,0.82,4.629c1.055,2.09,2.363,4.141,2.363,5.742c0,0.938,0.125,1.841-1.348,2.578C12.081,29.702,3,34,3,40 h17h17.238C37.238,34,28.156,29.702,26.684,28.965z"
                    ></path>
                </svg>
            </div>
            {{ page.props.auth.user.first_name }}
        </v-nav-link>
    </div>
    <div class="ml-3 relative xl:hidden block">
        <button
            type="button"
            @click="toggleProfileMenu"
            class="!text-white !hover:text-primary !bg-transparent !p-0"
        >
            <div class="image">
                <img
                    v-if="page.props.auth.user.profile_photo_url"
                    class="h-8 w-8 rounded-full object-cover"
                    :src="page.props.auth.user.profile_photo_url"
                    :alt="page.props.auth.user.name"
                />
                <svg class="ico" x="0px" y="0px" viewBox="0 0 40 40" v-else>
                    <path
                        d="M26.684,28.965c-1.473-0.737-1.348-1.641-1.348-2.578c0-1.602,1.309-3.652,2.363-5.742 c1.211-2.441,0.996-4.004,0.82-4.629c0.195-0.684,0.371-1.855,0.371-3.184c0-3.711-1.582-7.812-6.035-7.812 c-0.582,0-2.202,0.092-2.855,0.223c-5.083-0.223-8.653,3.878-8.653,7.589c0,1.328,0.176,2.5,0.371,3.184 c-0.176,0.625-0.391,2.188,0.82,4.629c1.055,2.09,2.363,4.141,2.363,5.742c0,0.938,0.125,1.841-1.348,2.578C12.081,29.702,3,34,3,40 h17h17.238C37.238,34,28.156,29.702,26.684,28.965z"
                    ></path>
                </svg>
            </div>
        </button>
    </div>
</template>

<style scoped lang="scss">
.image {
    float: left;
    display: block;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background-color: #f3f3f3;
    border-radius: 50%;
    margin-right: 10px;
    .ico {
        width: 30px;
        height: 30px;
        fill: #ccc;
    }
}
button,
a {
    &.mainbutton {
        color: var(--header-link-color);
    }
    &:not(.mainbutton) {
        color: var(--main-text-color);
        &:hover {
            background-color: var(--header-submenu-bg-color);
        }
    }
    @apply hover:text-primary text-sm flex items-center py-0 pr-6 pl-3.5 min-h-[30px] whitespace-nowrap;
    &.active {
        @apply font-bold;
    }
}
ul {
    @apply min-w-[180px];
}
</style>
