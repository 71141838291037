<script setup>
import { defineProps, ref } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/solid/index.js'

const props = defineProps({
    message: String,
    warningMessage: {
        type: String,
        default: 'Warning!',
    },
    showWarningMessage: {
        type: Boolean,
        default: false,
    },
    show: {
        type: Boolean,
        default: true,
    },
    dismissable: {
        type: Boolean,
        default: true,
    },
    bannerClass: {
        type: String,
        default: 'bg-amber-500',
    },
})

let showBanner = ref(props.show)

const close = () => {
    showBanner.value = false
}
</script>

<template>
    <div v-if="showBanner" :class="['w-full text-center p-2', bannerClass]">
        <p>
            <span class="font-bold" v-if="showWarningMessage">
                {{ warningMessage }}
            </span>

            {{ message }}

            <slot name="link" />

            <span v-if="dismissable" class="float-right">
                <x-mark-icon @click="close" class="w-4 h-4 cursor-pointer" />
            </span>
        </p>
    </div>
</template>
