<script setup>
import { computed } from 'vue'
import { Link, router } from '@inertiajs/vue3'
import _ from 'lodash'
import { ChevronRightIcon } from '@heroicons/vue/24/solid/index.js'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    level: {
        type: Number,
        default: 0,
        required: false,
    },
    class: {
        default: null,
        required: false,
    },
    dropdownAlign: {
        type: String,
        default: 'left',
        required: false,
    },
    withSvg: {
        type: Boolean,
        default: true,
        required: false,
    },
})
const generatedClasses = computed(() => {
    let classString = ''
    classString += 'level-' + props.level + ' !hover:text-primary px-3 py-2 text-sm '
    if (props.class !== null && typeof props.class === 'Array' && props.class.length > 0) {
        classString += props.class.join(' ')
    } else if (typeof props.class === 'string') {
        classString += props.class
    }

    if (props.item.active || childIsActive(props.item)) {
        classString += ' active'
    }
    if (props.level == 0) {
        classString += ' mainbutton'
    }
    return classString
})
const logout = () => {
    router.post(route('logout'))
}
function childIsActive(item) {
    if (item.children && item.children.length > 0) {
        return _.some(item.children, function (child) {
            return childIsActive(child)
        })
    } else {
        return item.named_route == route().current()
    }
}
</script>

<template>
    <div
        :class="'flex flex-col items-stretch'"
        v-if="props.item.children && props.item.children.length > 0"
    >
        <span :class="generatedClasses"><slot></slot></span>
        <v-mobile-nav-link
            v-for="subitem in props.item.children"
            :item="subitem"
            :level="level + 1"
            >{{ subitem.name }}</v-mobile-nav-link
        >
    </div>
    <form v-else-if="item.named_route == 'logout'" @submit.prevent="logout">
        <button type="submit" :class="generatedClasses + ' w-full'">
            <slot></slot>
        </button>
    </form>
    <Link v-else-if="item.route" :href="item.route" :class="generatedClasses">
        <div class="flex items-center">
            <chevron-right-icon v-if="props.level != 0" class="!custom-stroke w-3 h-3 mr-2" />
            <slot></slot>
        </div>
    </Link>
</template>

<style scoped lang="scss">
.line {
    height: 1px;
    background-color: #e1e1e1;
    margin: 10px 0;
}
.level-0 {
    @apply pl-[5%];
}
.level-1 {
    @apply pl-[7.5%];
}
.level-2 {
    @apply pl-[7.5%];
}
[class^='level-'] {
    @apply border-b leading-6;
    border-color: #4c555d;
}
.active {
    @apply font-bold;
}
button,
a,
span {
    @apply text-white hover:text-primary text-sm;
}
a.justify-end {
    div.flex {
        justify-content: end;
    }
}
</style>
