<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import VButton from '@/Components/BaseComponents/VButton.vue'
import { XMarkIcon } from '@heroicons/vue/24/solid/index.js'

const props = defineProps({
    type: {
        type: String,
        required: false,
    },
    duration: {
        type: Number,
        default: 3,
    },
})
const emits = defineEmits(['close'])
const show = ref()
onMounted(() => {
    resetToast()
})
function resetToast() {
    show.value = true // <div>
    if (props.duration != 0) {
        setTimeout(() => {
            show.value = false
        }, props.duration * 1000)
    }
}
const background = computed(() => {
    switch (props.type) {
        case 'success':
            return 'bg-lime-600'
        case 'error':
            return 'bg-red-700'
        case 'warning':
            return 'bg-yellow-400'
        case 'info':
            return 'bg-blue-600'
        default:
            return 'bg-gray-600'
    }
})
</script>

<template>
    <div :class="background + ' toast'" v-if="show">
        <slot></slot>
        <v-button type="button" kind="icon" class="ml-3 !p-0" @click="show = false"
            ><x-mark-icon class="!custom-stroke w-5 h-5 text-white transition hover:text-primary"
        /></v-button>
    </div>
</template>

<style scoped lang="scss">
.toast {
    transition: all 0.5s ease-in-out;
    @apply rounded-md opacity-75 px-5 py-3 shadow-lg text-white font-bold mt-4 flex flex-row justify-between items-center;
}
</style>
